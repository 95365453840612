import {createCamerasConnection} from '@netvision/lib-api-gateway';

const handleCommand = (promise: Promise<unknown>) => {
  return promise
    .then(() => true)
    .catch((e: unknown) => {
      console.error(e);
      return false;
    });
};

export const startAssignment = (id: string) =>
  handleCommand(
    createCamerasConnection().v2.updateEntityAttributes(
      {type: 'Assignment', id, startCommand: {}},
      {
        keyValues: true
      }
    )
  );

export const stopAssignment = (id: string) =>
  handleCommand(
    createCamerasConnection().v2.updateEntityAttributes(
      {type: 'Assignment', id, stopCommand: {}},
      {
        keyValues: true
      }
    )
  );

export const deleteAssignment = (id: string) =>
  handleCommand(createCamerasConnection().v2.deleteEntity({type: 'Assignment', id}));
