import {debounce} from 'lodash-es';

export interface IBatchQueue<I, O> {
  add(input: I, reject: () => void, resolve: (output: O) => void): void;
}

export interface IQueueEntry<I, O> {
  $id: number;
  args: I;
  resolve: (output: O) => void;
  reject: () => void;
}

export interface IActionInput<I> {
  $id: number;
  args: I;
}

export interface IActionOutput<O> {
  $id: number;
  result: O | undefined;
}

export const createBatchQueue = <I, O>(
  delay: number,
  action: (args: IActionInput<I>[]) => Promise<IActionOutput<O>[]>
): IBatchQueue<I, O> => {
  type _Map = Map<IQueueEntry<I, O>['$id'], IQueueEntry<I, O>>;
  let nextId = 0;
  let queue: _Map = new Map();

  const exec = debounce(() => {
    const q = queue;
    action(Array.from(queue.values()).map(({$id, args}) => ({$id, args: args}))).then((res) => {
      res.forEach(({$id, result}) => {
        const val = q.get($id);
        if (val) {
          if (result) {
            val.resolve(result);
          } else {
            val.reject();
          }
        }
      });
    });
  }, delay);

  return {
    add(args, reject, resolve) {
      const entry = {
        $id: nextId++,
        args,
        reject,
        resolve
      };
      const _q = queue;
      _q.set(entry.$id, entry);
      exec();
      return () => {
        _q.delete(entry.$id);
      };
    }
  };
};
