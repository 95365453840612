/** @jsx jsx */
import {jsx} from '@emotion/core';
import {FC} from 'react';
import {IAssignmentPriority} from '@netvision/lib-api-gateway';

export const Priority: FC<{priority: IAssignmentPriority; className?: string}> = ({priority, className}) => {
  return (
    <span className={className} style={{color: priority?.icon.color || undefined}}>
      {priority.title}
    </span>
  );
};
