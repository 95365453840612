import React, {useMemo, useEffect, useRef, useCallback} from 'react';
import {
  Table,
  IColumn,
  IFilterDescription,
  IColumnsSettings,
  applyColumnsSettings,
  INgsiOnLoadEvent,
  isString
} from '@netvision/lib-react-table';
import {useLocale} from '../hooks/useLocale';
import {
  createCamerasConnection,
  getSharedNotificationSocket,
  IAssignmentPriority,
  IAssignmentType,
  IEntity
} from '@netvision/lib-api-gateway';
import {Priority} from './helpers/Priority';
import {factoryUseEntityList} from '../hooks/factoryUseEntityList';
import {Status} from './helpers/Status';
import {Entity, EntityFetchProvider} from './helpers/Entity';
import {useEntitySearch} from '../hooks/factoryUseEntitySearch';
import {AssignmentStatus, AssignmentRejectReason} from '../AssignmentStatus';
import {AssignmentPermissionsProvider} from './helpers/AssignmentPermissionsProvider';
import {ModalProvider} from './helpers/ModalProvider';
import {ForceLoadProvider} from './helpers/ForceLoadProvider';
import {Title} from './helpers/Title';
import {ToastProvider} from '../hooks/useToastRef';
import {IAssignment} from '../IAssignment';
import {getEntitiesById, getPresetsByTitle} from '../presetsApi';
import {IOption} from '@netvision/lib-react-table/dist/src/utils/types';

export const Main = () => {
  const locale = useLocale();
  const [assignmentByField] = useEntitySearch('Assignment');
  const [cameraByField] = useEntitySearch('Camera');
  const types = useAssignmentTypes();
  const typesMap = useMap(types);
  const priorities = useAssignmentPriorities();
  const prioritiesMap = useMap(priorities);
  const columns = useMemo(() => {
    const all: IColumn<IAssignment, IAvailableField>[] = [
      {
        field: 'title',
        header: locale.assignmentNameLabel,
        body: (a) => <Title value={a.title} />
      },
      {
        field: 'assignmentTypeId',
        header: locale.assignmentTypeLabel,
        body: (a) => {
          const t = typesMap.get(a.assignmentTypeId);
          return typeof t === 'undefined' ? null : t.title;
        }
      },
      {
        field: 'priorityId',
        header: locale.priorityLabel,
        body: (a) => {
          const p = prioritiesMap.get(a.priorityId);
          return typeof p === 'undefined' ? null : <Priority priority={p} />;
        }
      },
      {
        field: 'entityTitle',
        header: locale.entityLabel,
        body: (a) => {
          return <Entity id={a.entityId} type={a.entityType} />;
        }
      },
      {
        field: 'entityId',
        header: locale.entityIdLabel,
        body: (a) => a.entityId
      },
      {
        field: 'entityType',
        header: locale.entityTypeLabel,
        body: (a) => a.entityType
      },
      {
        field: 'presetTitle',
        header: locale.presetLabel,
        body: (a) => {
          return a.parameters?.preset ? <Entity id={a.parameters.preset} type={'CameraPreset'} /> : <span>{'-'}</span>;
        }
      },
      {
        field: 'parameters.preset',
        header: locale.presetIdLabel,
        body: (a) => {
          return a.parameters?.preset;
        }
      },
      {
        field: 'rejectReason',
        header: locale.rejectReason,
        body: (a) => (a.rejectReason ? locale.assignmentRejectReason[a.rejectReason] || '' : '')
      },
      {
        field: 'status',
        header: locale.statusLabel,
        body: (a) => <Status assignment={a} />
      }
    ];
    return applyColumnsSettings(true, defaultColumnSettings(), undefined, all);
  }, [locale, prioritiesMap, typesMap]);
  const filterDescription = useMemo(() => {
    const fd: IFilterDescription<IAvailableField> = {
      title: {
        type: 'autocomplete',
        placeholder: locale.searchByName,
        minLength: 1,
        searchOptions: (query) =>
          assignmentByField(query, 'title', 30).then((res) => res.map((a) => a.title).filter(isString))
      },
      assignmentTypeId: {
        type: 'enum',
        options: types.map((t) => ({value: t.id, label: t.title}))
      },
      priorityId: {
        type: 'enum',
        options: priorities
          .slice()
          .sort((p1, p2) => p1.value - p2.value)
          .map((p) => ({value: p.id, label: p.title}))
      },
      entityTitle: {
        type: 'search',
        placeholder: locale.searchByName,
        field: 'entityId',
        maxEntries: 1,
        minLength: 1,
        getOptionsByValues: (values: Array<string | number>) =>
          getEntitiesById(values.map(String), 'Camera').then(entityToOptions),
        searchOptions: (query) =>
          cameraByField(query, 'title', 30).then((res) =>
            res.map((a) => ({value: a.id, label: a.title} as IOption<string>)).filter(({label}) => isString(label))
          )
      },
      entityId: {
        type: 'text',
        placeholder: locale.search
      },
      entityType: {
        type: 'text',
        placeholder: locale.search
      },
      presetTitle: {
        type: 'search',
        field: 'parameters.preset',
        placeholder: locale.search,
        maxEntries: 1,
        minLength: 1,
        getOptionsByValues: (values: Array<string | number>) =>
          getEntitiesById(values.map(String), 'CameraPreset').then(entityToOptions),
        searchOptions: (query: string) => getPresetsByTitle(query).then(entityToOptions)
      },
      rejectReason: {
        type: 'enum',
        options: Object.values(AssignmentRejectReason).map((v) => ({label: locale.assignmentRejectReason[v], value: v}))
      },
      status: {
        type: 'enum',
        options: Object.values(AssignmentStatus).map((v) => ({label: locale.assignmentStatusLocale[v], value: v}))
      }
    };
    return fd;
  }, [cameraByField, locale, priorities, types, assignmentByField]);
  const defaultPage = useMemo(() => {
    return {
      rows: 10,
      rowsPerPage: [10, 25, 50, 100],
      sortField: null,
      sortOrder: 1
    };
  }, []);
  const currentAssignmentsRef = useRef<Map<string, IAssignment> | null>(null);
  const forceLoadRef = useRef<(() => void) | null>(null);
  const forceLoad = useCallback(() => {
    forceLoadRef.current?.();
  }, []);
  useEffect(() => {
    const socket = getSharedNotificationSocket();
    if (socket) {
      return socket.addListener<IAssignment>('Assignment', (res) => {
        const forceLoad = forceLoadRef.current;
        const map = currentAssignmentsRef.current;
        if (forceLoad && map) {
          if (map.has(res.id)) {
            forceLoad();
          }
        }
      });
    } else {
      return undefined;
    }
  }, []);
  const dataSource = useMemo(
    () => ({
      type: 'Assignment',
      onLoad: (e: INgsiOnLoadEvent<IAssignment>) => {
        currentAssignmentsRef.current = toMap(e.result.data);
        forceLoadRef.current = e.forceLoad;
      }
    }),
    []
  );
  const localeOverrides = useMemo(
    () => ({
      noRecords: locale.noRecords
    }),
    [locale]
  );
  return (
    <ToastProvider position={'bottom-left'}>
      <ForceLoadProvider value={forceLoad}>
        <AssignmentPermissionsProvider>
          <EntityFetchProvider>
            <ModalProvider>
              <Table
                title={locale.title}
                dataKey={'id'}
                dataSource={dataSource}
                columns={columns}
                defaultPage={defaultPage}
                requireSortField={false}
                filterDescription={filterDescription}
                locale={localeOverrides}
              />
            </ModalProvider>
          </EntityFetchProvider>
        </AssignmentPermissionsProvider>
      </ForceLoadProvider>
    </ToastProvider>
  );
};

const useAssignmentTypes = factoryUseEntityList<IAssignmentType>('AssignmentType', createCamerasConnection);
const useAssignmentPriorities = factoryUseEntityList<IAssignmentPriority>(
  'AssignmentPriority',
  createCamerasConnection
);

export type IAvailableField =
  | 'title'
  | 'assignmentTypeId'
  | 'priorityId'
  | 'entityId'
  | 'entityType'
  | 'entityTitle'
  | 'status'
  | 'parameters.preset'
  | 'rejectReason'
  | 'presetTitle';

const defaultColumnSettings = (): IColumnsSettings<IAvailableField> => {
  return {
    title: {requiredColumn: true},
    assignmentTypeId: {requiredColumn: true},
    priorityId: {requiredColumn: true},
    entityTitle: {requiredColumn: true, sortable: 'entityId'},
    entityId: {hideByDefault: true},
    entityType: {hideByDefault: true},
    'parameters.preset': {hideByDefault: true, sortable: false},
    presetTitle: {hideByDefault: true, sortable: false},
    rejectReason: {hideByDefault: true, sortable: false},
    status: {requiredColumn: true}
  };
};

const toMap = <K extends string | number, E extends {id: K}>(arr: Array<E>): Map<K, E> =>
  new Map(arr.map((v) => [v.id, v]));
const useMap = <K extends string | number, E extends {id: K}>(arr: Array<E>): Map<K, E> =>
  useMemo(() => toMap(arr), [arr]);

const entityToOptions = (entity: IEntity[]) =>
  entity.map((p) => {
    return {
      value: p.id,
      label: p.title
    } as IOption<string>;
  });
