/** @jsx createElement */
import {createElement, createContext, FC, useCallback, useContext} from 'react';

const ForceLoadCtx = createContext<() => void>(null!);

export const useForceLoad = () => useContext(ForceLoadCtx);

export const ForceLoadProvider: FC<{value: () => void}> = ({value, children}) => {
  return <ForceLoadCtx.Provider value={value}>{children}</ForceLoadCtx.Provider>;
};
