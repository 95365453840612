import React, {createContext, FC, useContext} from 'react';
import {LocaleProvider} from './hooks/useLocale';
import {BrowserRouter} from 'react-router-dom';
import {Main} from './components/Main';
import {IWidgetProps} from './IWidgetProps';

const WidgetPropsContext = createContext<IWidgetProps>(null!);

export function useWidgetProps() {
  return useContext(WidgetPropsContext);
}

const Root: FC<IWidgetProps> = (widgetProps) => {
  return (
    <WidgetPropsContext.Provider value={widgetProps}>
      <LocaleProvider>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </LocaleProvider>
    </WidgetPropsContext.Provider>
  );
};

export default Root;
