/** @jsx jsx */
import {jsx} from '@emotion/core';
import {FC, Fragment} from 'react';
import {useDialogVisible} from './useDialogVisible';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {deleteAssignment} from '../../assignmentCommands';
import {useForceLoad} from './ForceLoadProvider';
import {useLocale} from '../../hooks/useLocale';
import {useToastRef} from '../../hooks/useToastRef';

export const DeleteModal: FC<{id: string; onClose: () => void}> = ({id, onClose: _onHide}) => {
  const locale = useLocale();
  const [visible, onHide] = useDialogVisible(_onHide);
  const forceLoad = useForceLoad();
  const footer = (
    <Fragment>
      <Button
        className={'p-button-danger'}
        label={locale.delete}
        onClick={() =>
          deleteAssignment(id)
            .then(() => {
              toastRef.current?.show({
                severity: 'success',
                summary: locale.success,
                detail: locale.deleteDialog.success
              });
              onHide();
              forceLoad();
            })
            .catch((e: any) => {
              console.error(e);
              toastRef.current?.show({severity: 'error', summary: locale.error, detail: locale.deleteDialog.error});
            })
        }
      />
      <Button className={'p-button-secondary'} label={locale.cancel} onClick={onHide} />
    </Fragment>
  );
  const toastRef = useToastRef();
  return (
    <Dialog header={locale.deleteDialog.header} visible={visible} onHide={onHide} footer={footer}>
      <div style={{padding: '0 var(--form-padding)'}}>{locale.deleteDialog.message}</div>
    </Dialog>
  );
};
