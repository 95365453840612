import {IAssignment} from '@netvision/lib-api-gateway';
declare module '@netvision/lib-api-gateway' {
  interface IAssignment {
    status: AssignmentStatus;
  }
}
export enum AssignmentRejectReason {
  'ByTimeout' = 'ByTimeout',
  'ByError' = 'ByError',
  'IsNotConfirmed' = 'IsNotConfirmed',
  'external_ByError' = 'external_ByError',
  'external_ByTimeout' = 'external_ByTimeout'
}

export enum AssignmentStatus {
  'Stopped' = 'Stopped',
  'NotStarted' = 'NotStarted',
  'Started' = 'Started',
  'Starting' = 'Starting',
  'Stopping' = 'Stopping',
  'Rejected' = 'Rejected'
}
