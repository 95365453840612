import {createCamerasConnection, IEntity, listEntities} from '@netvision/lib-api-gateway';
import {useMemo} from 'react';
import {ngsiCaseInsensitive} from '../utils/ngsiCaseInsensitive';
import {ngsiSanitize} from '../utils/ngsiSanitize';

export const useEntitySearch = <E extends IEntity>(type: E['type'] | Array<E['type']>) => {
  return useMemo(() => {
    return [
      // by Field
      (query: string, field: string, limit: number): Promise<E[]> =>
        load({
          type: join(type, ','),
          orderBy: field,
          limit,
          q: query.length > 0 ? `${field}~=${ngsiCaseInsensitive(ngsiSanitize(query))}` : undefined
        }),
      // by Id
      (id: string | string[]): Promise<E[]> => {
        id = join(id, ',');
        return id.length > 0 ? load({id, type: join(type, ',')}) : Promise.resolve([]);
      }
    ] as const;
  }, [type]);
};

const load = <E extends IEntity>(params: {
  type: string;
  id?: string;
  q?: string;
  limit?: number;
  orderBy?: string;
}): Promise<E[]> =>
  listEntities<E>(createCamerasConnection(), {
    ...params
  })
    .then(({results}) => (results.every(isObject) ? results : []))
    .catch(() => []);

const join = (s: string | string[], separator: string) => (Array.isArray(s) ? s.join(separator) : s);

const isObject = <T extends object>(v: unknown): v is T => typeof v === 'object' && v !== null;
