/** @jsx jsx */
import {FC} from 'react';
import {css, jsx} from '@emotion/core';

export const Title: FC<{value: string}> = ({value}) => {
  return value ? <span css={$title}>{value}</span> : null;
};

// language=SCSS
const $title = css`
  & {
    font-weight: 500;
    color: var(--text-color);
  }
`;
