/** @jsx jsx */
import {jsx, css} from '@emotion/core';
import {FC, useEffect, useRef} from 'react';
import {useDialogVisible} from './useDialogVisible';
import {useWidgetProps} from '../../Root';
import {Widget} from '@netvision/lib-widget-renderer';
import {IWidgetProps} from '../../IWidgetProps';
import {IAssignment} from '../../IAssignment';
import {useLocale} from '../../hooks/useLocale';
import {Button} from 'primereact/button';

export const EditAssignmentModal: FC<{assignment: IAssignment; onClose: () => void}> = ({
  assignment,
  onClose: _onHide
}) => {
  const locale = useLocale();
  const [visible, onHide] = useDialogVisible(_onHide);
  return (
    <div css={[$editModal, $visible]} data-visible={visible}>
      <div css={$back}>
        <Button onClick={onHide} className={'p-button-text p-button-secondary'} icon={'mdi mdi-20px mdi-arrow-left'} />
        <h2>{locale.back}</h2>
      </div>
      <div css={$content}>
        <Inner assignment={assignment} onClose={onHide} />
      </div>
    </div>
  );
};

const $editModal = css`
  position: absolute;
  top: calc(-100rem / var(--bfs));
  left: 0;
  z-index: 1;

  margin: 0 calc(30rem / var(--bfs));
  padding-top: calc(200rem / var(--bfs));
  width: calc(100vw - 60rem / var(--bfs));
  background: var(--body-bg);
`;

const $visible = css`
  &[data-visible] {
    transition: opacity 1s ease;
  }
  &[data-visible='true'] {
    opacity: 1;
  }
  &[data-visible='false'] {
    opacity: 0;
  }
`;

const $back = css`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacer-xs);

  > button {
    margin-right: var(--spacer-sm);
  }
  > h2 {
    font-size: var(--font-size-h2);
    margin: 0;
    font-weight: 400;
  }
`;

const $content = css`
  height: calc(100vh - 150rem / var(--bfs));
  padding-bottom: calc(30rem / var(--bfs));
  overflow: auto;
`;

const Inner: FC<{assignment: IAssignment; onClose: () => void}> = ({assignment, onClose}) => {
  const {mountChildren, areas} = useWidgetProps();
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const node = ref.current;
    const {dialog} = getAreasChildren(areas);
    if (node && dialog) {
      return mountChildren(node, [
        {
          ...dialog,
          props: {
            ...dialog.props,
            cameraId: assignment.entityId,
            assignmentId: assignment.id,
            onClose
          }
        }
      ]);
    }
    return undefined;
  }, [assignment, mountChildren, areas, onClose]);
  return <div ref={ref} />;
};

function getAreasChildren(
  areas: IWidgetProps['areas']
): {
  dialog: Widget | null;
} {
  let dialog = null;
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === 'dialog' && area.children && area.children.length > 0) {
        dialog = area.children[0];
      }
    });
  }
  return {
    dialog
  };
}
