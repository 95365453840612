import {createCamerasConnection, ICamera, IEntity, listEntities} from '@netvision/lib-api-gateway';
import {ngsiSanitize} from './utils/ngsiSanitize';
import {ngsiCaseInsensitive} from './utils/ngsiCaseInsensitive';

const C_defaultCameraThreshold = {
  x: 0.001,
  y: 0.001,
  zoom: 0.003
};

export const isInPreset = (cameraId: string, presetId: string): Promise<boolean> => {
  return Promise.all([getPtzPreset(presetId), getPTZData(cameraId)]).then(([preset, ptzData]) => {
    return getEqPtzValues(ptzData?.ptzSettings?.threshold ?? C_defaultCameraThreshold).equals(
      preset?.status?.position,
      ptzData?.status?.position
    );
  });
};

export const getPresetsByTitle = (title: string): Promise<ICameraPreset[]> => {
  return listEntities<ICameraPreset>(createCamerasConnection(), {
    type: 'CameraPreset',
    q: `title~=${ngsiCaseInsensitive(ngsiSanitize(title))}`,
    limit: 20
  })
    .then((res) => {
      return res.results;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const getEntitiesById = (ids: string[], type: string): Promise<IEntity[]> => {
  return listEntities<IEntity>(createCamerasConnection(), {
    type: type,
    id: ids.join(',')
  })
    .then((res) => {
      return res.results;
    })
    .catch((e) => {
      console.error(e);
      return [];
    });
};

function getPtzPreset(presetId: string): Promise<ICameraPreset | null> {
  return createCamerasConnection()
    .v2.getEntity({
      id: presetId,
      type: 'CameraPreset',
      keyValues: true
    })
    .then((res: {entity: ICameraPreset}) => {
      return res.entity ?? null;
    })
    .catch((e: unknown) => {
      console.error(e);
      return null;
    });
}

function getPTZData(cameraId: string): Promise<IPtzData | null> {
  return listEntities(createCamerasConnection(), {
    id: cameraId,
    type: 'Camera',
    attrs: 'status,ptzSettings'
  })
    .then((res) => {
      if (res.results.length > 0) {
        const camera = res.results[0];
        if ('status' in camera && 'ptzSettings' in camera) {
          const {ptzSettings, status} = camera as ICameraWithPTZ;
          return {
            status,
            ptzSettings
          };
        } else {
          console.error(Error('Camera does not have required ptz fields: "status", "ptzSettings"'), res);
          return null;
        }
      } else {
        console.error(Error('Unexpected result, expect exactly one entity'), res);
        return null;
      }
    })
    .catch((e: unknown) => {
      console.error(e);
      return null;
    });
}

export interface ICameraPreset extends IEntity {
  id: string;
  type: 'CameraPreset';
  cameraId: string;
  title: string;
  status: {
    position: IPtzValues;
  };
  groundCalibration: Record<string, unknown>;
}

interface IPtzData {
  status: {
    position: IPtzValues;
  };
  ptzSettings: IPtzSettings;
}

interface ICameraWithPTZ extends ICamera, IPtzData {}

export interface IPtzSettings {
  delay?: number;
  threshold?: IPtzValues;
}

export interface IPtzValues {
  /**
   *  -1 < x < 1
   */
  x: number;
  /**
   *  -1 < y < 1
   */
  y: number;
  /**
   *  -1 < zoom < 1
   */
  zoom: number;
}

interface Eq<A> {
  readonly equals: (a: A, b: A) => boolean;
}

const getEqNumber = (threshold: number): Eq<number> => {
  return {
    equals: (a, b) => Math.abs(a - b) < threshold
  };
};

export const getEqPtzValues = (threshold: IPtzValues): Eq<IPtzValues | undefined> => {
  const eqEntries = threshold
    ? Object.entries(threshold).map(([key, value]) => [key, getEqNumber(value)] as [keyof IPtzValues, Eq<number>])
    : [];
  return {
    equals: (a, b) => {
      if (a === b) {
        return true;
      }
      if (typeof a !== 'object' || typeof b !== 'object') {
        return false;
      }
      return eqEntries.every(([key, eq]) => eq.equals(a[key], b[key]));
    }
  };
};
